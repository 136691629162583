<template>
    <div>
        <div class="table_content">

            <!-- 表格内容 -->
            <commonTitle></commonTitle>
            <div class="search">
                <el-form ref="form" :model="form" label-width="75px" :inline="true">
                    <el-form-item label="小区名称:">
                        <el-input v-model="form.name" placeholder="请输入小区名称" size="mini"></el-input>
                    </el-form-item>
                    <button @click.prevent="common()" style="cursor: pointer">搜索</button>
                </el-form>
            </div>
            <el-card>
                <el-table
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        :data="tableData"
                        style="width:100%"
                        
                        :default-sort="{prop: 'settingTime', order: 'descending'}"
                >
                    <!--                <el-table-column type="expand">-->
                    <!--                    <template slot-scope="props">-->
                    <!--                        <el-form label-position="left" inline class="demo-table-expand">-->
                    <!--                            <el-form-item label="申请理由">-->
                    <!--                                <span style=" word-break: break-all;">{{ props.row.applicationAdvice}}</span>-->
                    <!--                            </el-form-item>-->
                    <!--                        </el-form>-->
                    <!--                    </template>-->
                    <!--                </el-table-column>-->
                    <el-table-column prop="villageName" label="小区名称" width="150" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="villageCard" label="小区编号" width="120" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="orgName" label="物业单位" width="120" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="createDate" label="申请时间" width="120" :formatter="formatCreateDate"
                                     show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="applicationAdvice" label="申请理由" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="160">
                        <template slot-scope="scope">
                            <el-button
                                    size="mini"
                                    @click="pass(scope.row,2)"
                                    :disabled="scope.row.status=='1'"
                                    type="success"
                            >通过
                            </el-button>
                            <el-button
                                    size="mini"
                                    @click="npPass(scope.row,2)" :disabled="scope.row.status=='1'">驳回
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        background
                        style="margin-top: 50px"
                        layout="prev, pager, next"
                        :page-size="15"
                        @current-change="handleCurrentChange"
                        :total="totalNum">
                </el-pagination>
            </el-card>
        </div>
    </div>
</template>

<script>
    import commonTitle from "../../table/commonTitle";

    export default {
        name: "villageApply",
        components: {
            commonTitle
        },
        data() {
            return {
                tableData: [],
                url: this.$Config.base_server,
                totalNum: 15,
                loading: false,
                form: {
                    name: "",
                    region: '',
                }, data: {
                    //查询条件
                    limit: 15,
                }
            }
        },
        mounted() {
            this.common();
        },
        methods: {
            common(currentPage = 0) {
                this.loading = true;
                const that = this;

                that.data.page = currentPage;
                that.data.villageName = that.form.name;
                const URL = this.url + "/api-public/opevillage/findvillageApply?access_token=" + localStorage.getItem("token");
                $.ajax({
                    url: URL,
                    contentType: "application/json",
                    data: that.data,
                    type: "get",
                    success: function (res) {
                        if (res.code == 0) {
                            that.totalNum = res.count;
                            that.tableData = res.data;
                            that.loading = false;
                        }
                    }
                })
            },
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleCurrentChange(val) {//当前页数改变
                this.common(val)
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            }, handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {
                    });
            }, pass(row, state) {
                //通过按钮
                this.loading = true;
                this.row = row;
                //提交后台数据
                const data = {
                    tenementId: this.row.tenementId,
                    villageId: row.villageId,
                    id: row.id
                };
                console.log(data);
                var _this = this;
                $.ajax({
                    url: _this.url + "/api-public/opevillage/updateVillageApply?access_token=" + localStorage.getItem("token"),
                    type: 'post',
                    data: JSON.stringify(data),
                    contentType: 'application/json',
                    success: function (res) {
                        _this.loading = false;
                        if (res.resp_code == 0) {
                            //通过成功
                            _this.$message({
                                type: 'success',
                                message: res.resp_msg
                            });
                            row.status = state;
                        } else {
                            //通过失败
                            _this.$message({
                                type: 'warning',
                                message: res.resp_msg
                            });
                        }
                        _this.common();
                    }
                });
            }, formatStatus(row) {
                //格式化状态
                //控制审批状态
                if (row.status == '0') {
                    return '未提交';
                } else if (row.status == '1') {
                    return '审批通过';
                } else if (row.status == '2') {
                    return '审批拒绝';
                } else if (row.status == '3') {
                    return '审批中';
                }
            }, formatCreateDate(row) {
                //格式话申请时间
                let date = new Date(row.createDate);
                let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
                let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                return date.getFullYear() + "-" + month + "-" + day;
            }, npPass(row, state) {
                this.loading = true;
                //驳回按钮
                const data = {
                    id: row.id
                };
                const _this = this;
                $.ajax({
                    url: _this.url + "/api-public/opevillage/updateStatus?access_token=" + localStorage.getItem("token"),
                    contentType: 'application/json',
                    type: 'post',
                    data: JSON.stringify(data),
                    success: function (res) {
                        _this.loading = false;
                        if (res.resp_code == 0) {
                            //驳回成功
                            _this.$message({
                                type: 'success',
                                message: res.resp_msg
                            });

                            row.status = state;
                        } else {
                            //驳回失败
                            _this.$message({
                                type: 'warning',
                                message: res.resp_msg
                            });
                        }
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .demo-table-expand {
        font-size: 0;
    }

    .demo-table-expand label {
        width: 90px;
        color: #99a9bf;
    }

    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
    }

    .el-form-item label {
        color: red;;
    }
</style>